<template>
  <b-card-code
    no-body
    title="Lịch thi"
  >
    <b-table
      responsive="sm"
      :items="items"
      style="text-align: center;"
    />
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import moment from 'moment'

export default {
  components: {
    BCardCode,
    BTable,
  },
  data() {
    return {
      items: [],
      user: getUser(),
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'examination/examinationRooms',
    }),
  },
  async created() {
    await this.getInitData()
  },
  methods: {
    ...mapActions({
      getDataSource: 'examination/getListExamination',
    }),
    transformData(data) {
      return data.map(item => ({
        Phương_thức_xét_tuyển: item.admissionFormName,
        Môn_thi: item.admissionSubjectName,
        Phòng_thi: item.roomName ? item.roomName : 'Chưa có phòng',
        Ngày_thi: moment(item.startAt).format('DD-MM-YYYY'),
        Thời_gian: `${moment(item.startAt).format('HH:mm')}`,
      }))
    },
    async getInitData() {
      const params = {
        admissionRoundId: this.user.admissionRoundId,
        applicantStudentId: this.user.id,
      }
      await this.getDataSource(params)
      this.items = this.transformData(this.dataSources)
    },
  },
}
</script>
